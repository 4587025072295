import React, { useEffect, useState } from 'react'
import { FormWrapper } from './MailingListForm.css'

const apiBase =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3007'
    : 'https://api2.hirezstudios.com'

export function MailingListForm(props) {
  const { sendInBlueListID } = props
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    overThirteen: false,
    sendMarketing: false,
  })
  const [FormMessage, setFormMessage] = useState(false)
  const [submissionSuccess, setSubmissionSuccess] = useState(false)

  function validateForm() {
    //handle required fields
    if (!formData['email']) {
      setFormMessage('An email address is required for submission.')
      return false
    }

    //confirm email is valid
    if (
      !/^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(
        formData['email'].toLowerCase()
      )
    ) {
      setFormMessage('Your email address does not appear to be valid.')
      return false
    }
    return true
  }

  const submitForm = e => {
    e.preventDefault()
    setFormMessage(undefined)
    if (validateForm()) {
      fetch(`${apiBase}/acct/sendInBlue`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: `{
              "firstName": "${formData['firstName']}",
              "lastName": "${formData['lastName']}",
              "email": "${formData['email']}",
              "optOut": "${formData['sendMarketing'] === true ? 'n' : 'y'}"
            }`,
      })
        .then(res => res.json())
        .then(res => {
          return fetch(`${apiBase}/acct/sendInBlue/updatecontactlistsnotoken`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: `{
                  "email":"${formData.email}",
                  "addList": "${sendInBlueListID}"
                }`,
          })
        })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200) {
            setFormMessage(`Thank you for signing up! We'll keep you updated with the latest news and information about the SMITE 2 Founder's Series as soon as it's available. Stay tuned!`)
            setSubmissionSuccess(true)
          }
        })
    }
  }

  return (
    <FormWrapper>
      <div className="form-container">
        <form onSubmit={e => submitForm(e)}>
          <input
            type=""
            placeholder="First Name"
            name="firstName"
            onChange={e =>
              setFormData({ ...formData, firstName: e.target.value })
            }
            required
          />
          <input
            type=""
            placeholder="Last Name"
            name="lastName"
            onChange={e =>
              setFormData({ ...formData, lastName: e.target.value })
            }
            required
          />
          <input
            type="email"
            placeholder="Email"
            name="email"
            onChange={e => setFormData({ ...formData, email: e.target.value })}
            required
          />
          <label htmlFor="over-thirteen">
            <input
              type="checkbox"
              name="over-thirteen"
              onChange={e =>
                setFormData({ ...formData, overThirteen: e.target.checked })
              }
              required
            />
            <span>
              <p>
                {'I am over 13+ years of age and agree to the '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://webcdn.hirezstudios.com/hirez-studios/wp-content/uploads/2015/11/Hi-Rez-Studios-Online-Terms-of-Service-and-Use-Agreement-Last-updated-Aug.-24-2017.pdf"
                >
                  {'Terms of Service'}
                </a>
              </p>
            </span>
          </label>

          <label htmlFor="send-marketing">
            <input
              type="checkbox"
              name="send-marketing"
              onChange={e =>
                setFormData({ ...formData, sendMarketing: e.target.checked })
              }
            />
            {'Send me SMITE 2 news'}
          </label>
          <button
            onClick={submitForm}
            onKeyDown={e => (e.key === 'Enter' ? submitForm() : null)}
            role="button"
          >
            {'Continue'}
          </button>
          {FormMessage && (
            <div
              className={`message ${
                submissionSuccess === true ? 'success' : ''
              }`}
            >
              {FormMessage}
            </div>
          )}
        </form>
      </div>
    </FormWrapper>
  )
}
