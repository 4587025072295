import React from 'react';
import { Link } from 'gatsby';
import withContext from 'store/withContext';

import {MailingListForm} from 'components/Forms';

// import Button from 'components/Global/Button';

import PageWrapper from '../styles/vegas.css.js';

const Leagues = (props) => {

  return (
    <PageWrapper>
      <main className="vegas-content">
        <section className="header">
          <img className="logo" src={'https://webcdn.hirezstudios.com/smite-esports/assets/s2v-logo.png'}/>
        </section>
        
        <div className="center-wrapper">
          <section className="about">
            <h1>JANUARY 17-19</h1>
            <h2>HYPERX ARENA LAS VEGAS</h2>
            <h5>JOIN US AND EXPERIENCE THE EXCITEMENT AT THE FIRST LIVE SMITE 2 ESPORTS EVENT</h5>
            <h6>Sign up to receive updates on ticket packages as soon as they become available.</h6>
          </section><section className="about">
            <div className="neonBorder">
              <MailingListForm sendInBlueListID={271}/>
            </div>
          </section>
        </div>
      </main>
    </PageWrapper>
  );
};

export default withContext(Leagues);
