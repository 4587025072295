import styled from 'styled-components'

export const FormWrapper = styled.div`
  .form-container {
    margin: 0 auto;
    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      label {
        display: inline-flex;
      }
    }
  }
`
